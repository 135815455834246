import React, { useEffect } from "react";
import { DefaultUser } from "../../assets/svg/AllSvg";
import Breadcrumb from "../../common/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { getCustomer } from "../../redux/customer/slice";
import { useDispatch, useSelector } from "react-redux";
import RippleLoader from "../../common/RippleLoader";

const ViewCustomer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const customerData = location?.state?.customer;

  //  Selector
  const { getCustomerData, loading } = useSelector((store) => ({
    getCustomerData: store?.customerData?.getCustomerStatus?.data?.data,
    loading: store?.customerData?.loading,
  }));

  useEffect(() => {
    if (customerData?.id) {
      dispatch(getCustomer(customerData?.id));
    }
  }, [dispatch, customerData]);
  return (
    <>
      {loading ? (
        <>
          <RippleLoader />
        </>
      ) : (
        <div className="space-y-5">
          <div className="mb-3">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <DefaultUser />
                <h6 className="font-opensans font-normal sm:text-xl text-lg ">
                  Customers
                </h6>
              </div>
            </div>
            <Breadcrumb
              breadCrumbParent={"Home"}
              breadCrumbParent2={"Customers"}
              breadCrumbActive={"Edit Customers"}
              breadCrumbTitleKey={"/"}
              classes=""
            />
          </div>
          <div className="bg-lightGrey p-6 rounded-lg">
            <div className="flex justify-between items-center border-b border-b-secondary_grey pb-5 mb-4">
              <div className="flex items-center gap-2">
                <DefaultUser />
                <p className="text-xl font-normal text-primary_black">
                  Customer Details
                </p>
              </div>
              <div>
                <p
                  className="text-mainthemeBlack border-b border-b-primaryBlack cursor-pointer"
                  onClick={() =>
                    navigate("/create-edit-customer", {
                      state: { customer: customerData, mode: "edit" },
                    })
                  }
                >
                  Edit
                </p>
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <p className="text-sm font-normal text-primary_black">
                  Customer Name:
                </p>
                <p className="text-base font-bold text-primary_black">
                  {getCustomerData?.full_name}
                </p>
              </div>
              <div>
                <p className="text-sm font-normal text-primary_black">
                  Company Name:
                </p>
                <p className="text-base font-bold text-primary_black">
                  {getCustomerData?.company_name}
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="text-sm font-normal text-primary_black">
                    Office Number:
                  </p>
                  <p className="text-base font-bold text-primary_black">
                    {getCustomerData?.office_number
                      ? `+${getCustomerData.office_number}`
                      : `-`}
                  </p>
                </div>
                <div>
                  <p className="text-sm font-normal text-primary_black">
                    Mobile Number:
                  </p>
                  <p className="text-base font-bold text-primary_black">
                    {getCustomerData?.phone ? `+${getCustomerData.phone}` : `-`}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-sm font-normal text-primary_black">Email:</p>
                <p className="text-base font-bold text-primary_black">
                  {getCustomerData?.email}
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="text-sm font-normal text-primary_black">
                    Address:
                  </p>
                  <p className="text-base font-bold text-primary_black">
                    {getCustomerData?.address}
                  </p>
                </div>
                <div>
                  <p className="text-sm font-normal text-primary_black">
                    Postal Code:
                  </p>
                  <p className="text-base font-bold text-primary_black">
                    {" "}
                    {getCustomerData?.postal_code}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewCustomer;
