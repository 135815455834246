import React, { useEffect, useState } from "react";
import {
  InputWithLabel,
  SelectBox,
  TextareaWithLabel,
} from "../../../components/micro";
import { useDispatch, useSelector } from "react-redux";
import { getPackageList } from "../../../redux/package/slice";
import { Form, Formik } from "formik";
import { Decriment, DeleteIcon, Increment } from "../../../assets/svg/AllSvg";
import ConfirmationModel from "../../../common/ConfirmationModel";

const Package = ({
  data,
  removePackges,
  index,
  updatePackageDetail,
  setIsDisable,
}) => {
  const [packageArray, setPackageArray] = useState([]);
  const [showPackagePrice, setShowPackagePrice] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dispatch = useDispatch();

  const { packageList, packageListLoading } = useSelector((store) => ({
    // Package List
    packageList: store?.packageData?.getPackageListStatus?.data?.data,
    packageListLoading: store?.packageData?.loading,
  }));

  //Get Package List
  useEffect(() => {
    if (!packageList?.length) {
      dispatch(
        getPackageList({
          pageCount: 1,
          pageLimit: "all",
          sort_by: "",
          sort_direction: "desc",
        })
      );
    }
  }, [packageList, dispatch]);

  //   set Package Option Array
  useEffect(() => {
    if (packageList?.length) {
      let data = packageList?.map((x) => ({
        ...x,
        label: x?.name,
      }));
      setPackageArray(data);
    }
  }, [packageList]);

  const handleIncrement = () => {
    updatePackageDetail(
      index,
      data?.package_id,
      data?.description,
      data?.price,
      data?.no_of_items + 1
    );
  };
  const handleDecrement = () => {
    if (data?.no_of_items > 1) {
      updatePackageDetail(
        index,
        data?.package_id,
        data?.description,
        data?.price,
        data?.no_of_items - 1
      );
    }
  };

  return (
    <>
      <div className="card border border-gray-300 rounded-lg shadow-s">
        <Formik
          initialValues={{
            package_id: data?.package_id ?? "",
            description: data?.description ?? "",
            price: data?.price ?? "",
          }}
          onSubmit={(values) => {}}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-4 w-full">
                  <div className=" flex-1">
                    <SelectBox
                      id="package_id"
                      placeholder="Package"
                      isLoading={packageListLoading}
                      options={packageArray}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.id}
                      value={
                        packageArray?.length > 0 &&
                        packageArray?.find((op) => {
                          return op?.id === values?.package_id;
                        })
                      }
                      onChange={(e) => {
                        setFieldValue("package_id", e?.id);
                        setShowPackagePrice(e?.price);
                        updatePackageDetail(
                          index,
                          e.id,
                          data?.description,
                          data?.price,
                          data?.no_of_items
                        );
                      }}
                    />
                  </div>

                  {/* Increment / Decrement Buttons */}
                  <div className="flex items-center space-x-2">
                    <button
                      type="button"
                      className="w-8 h-8 flex items-center justify-center  rounded-full "
                      onClick={handleDecrement}
                    >
                      <Decriment />
                    </button>
                    <span className="text-base">{data?.no_of_items}</span>
                    <button
                      type="button"
                      className="w-8 h-8 flex items-center justify-center  rounded-full "
                      onClick={handleIncrement}
                    >
                      <Increment />
                    </button>
                  </div>
                </div>
              </div>
              {showPackagePrice === 0 ? (
                <>
                  <TextareaWithLabel
                    className={
                      errors.description && touched.description
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="description"
                    label="Description"
                    onChange={(e) => {
                      handleChange(e);
                      setIsDisable(false);
                      updatePackageDetail(
                        index,
                        data?.package_id,
                        e.target.value,
                        data?.price,
                        data?.no_of_items
                      );
                    }}
                    onBlur={handleBlur}
                    value={data.description}
                    errors={errors.description && touched.description}
                    errorsText={errors.description}
                  />
                  <InputWithLabel
                    className={`text-black border hover:border-themeBtn  ${
                      errors.price && touched.price
                        ? `border border-red`
                        : `border border-borderTheme/80 focus-within:border-themeBtn `
                    }  transition duration-300 ease-in-out`}
                    idFromName="price"
                    label="Price"
                    type="number"
                    onChange={(e) => {
                      handleChange(e);
                      updatePackageDetail(
                        index,
                        data?.package_id,
                        data?.description,
                        e.target.value,
                        data?.no_of_items
                      );
                    }}
                    onBlur={handleBlur}
                    value={data.price}
                    errors={errors.price && touched.price}
                    errorsText={errors.price}
                  />
                </>
              ) : (
                <p className="text-mainthemeBlack text-base">
                  Price: ${showPackagePrice}
                </p>
              )}
              {/* Delete Button */}
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setConfirmDelete(true)}
                  className="text-red-500 hover:text-red-700"
                >
                  <DeleteIcon />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {confirmDelete && (
        <div>
          <ConfirmationModel
            confirmText={"Remove"}
            onClose={() => {
              setConfirmDelete(false);
            }}
            onConfirm={() => {
              removePackges(index);
              setConfirmDelete(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default Package;
