import React from "react";
import { LoaderSvg } from "../assets/svg/AllSvg";

const ButtonLoader = () => {
  return (
    <div className="text-mainthemeBlack font-medium text-sm py-1.5 inline-flex items-center">
      <LoaderSvg pathClass={"fill-mainthemeBlack"} />
      <span className="ml-3">Loading...</span>
    </div>
  );
};

export default ButtonLoader;
