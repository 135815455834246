import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputWithLabel } from "../../components/micro";
import { ResetLock } from "../../assets/svg/AllSvg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { newPassword } from "../../redux/auth/slice";

const NewPassword = () => {
  const [loading, setLoading] = useState(false);
  const { email, token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="main flex justify-center  items-center h-screen">
        <section className="w-full ">
          <div className="max-w-sm w-full mx-auto">
            <div className="flex items-center justify-center text-center">
              <ResetLock />
            </div>
            <div className="main-view">
              <div className="my-8 text-center ">
                <h1 className="sm:text-2xl text-4xl font-opensans font-normal mb-1">
                  Reset Password
                </h1>
                <p className="text-2xl  mb-1 font-opensans font-normal">
                  Key in your new password
                </p>
              </div>
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                  role: "admin",
                }}
                validationSchema={Yup.object({
                  password: Yup.string().required("Please Enter New Password"),
                  confirmPassword: Yup.string()
                    .required("Please Enter Confirm New Password")
                    .oneOf([Yup.ref("password"), null], "Passwords must match"),
                })}
                onSubmit={(values) => {
                  setLoading(true);
                  dispatch(
                    newPassword({ email: email, code: token, ...values })
                  ).then((res) => {
                    setLoading(false);
                    if (res.type === "newPassword/fulfilled") {
                      navigate("/success-password");
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="space-y-5">
                      {/* New Password */}
                      <InputWithLabel
                        className={
                          errors.password && touched.password
                            ? ` input-error `
                            : ` border `
                        }
                        idFromName="password"
                        label="New Password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        errors={errors.password && touched.password}
                        errorsText={errors.password}
                      />
                      {/* Confirm New Password */}
                      <InputWithLabel
                        className={
                          errors.confirmPassword && touched.confirmPassword
                            ? ` input-error `
                            : ` border `
                        }
                        idFromName="confirmPassword"
                        label="Confirm New Password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        errors={
                          errors.confirmPassword && touched.confirmPassword
                        }
                        errorsText={errors.confirmPassword}
                      />
                      <button
                        type="submit"
                        className="w-full block text-white bg-mainthemeBlack text-center text-base border-2 border-transparent uppercase rounded transition duration-500 py-2 lg:px-7 px-4"
                      >
                        {loading ? "Loading...." : "Confirm New Password"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NewPassword;
