import React from "react";
import ButtonLoader from "./ButtonLoader";

const ConfirmationModel = ({ onClose, confirmText, onConfirm, isLoading }) => {
  return (
    <>
      <div className="model" onClick={onClose}>
        <div
          className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-md min-h-40 py-8 px-6 mx-auto relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="sm:text-[34px] text-xl font-ebGaramond mb-3">
            Confirm {confirmText}?
          </h2>
          {isLoading ? (
            <ButtonLoader />
          ) : (
            <div className="space-x-2 flex">
              <button
                className="text-primary_black border border-primary_black flex items-center justify-center px-6 py-2.5 rounded uppercase"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="bg-mainthemeBlack  text-white flex items-center justify-center px-6 py-2.5 rounded uppercase"
                onClick={onConfirm}
              >
                Confirm
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ConfirmationModel;
