import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  Calender,
  DollarVactor,
  Paper,
  SavedVector,
} from "../../assets/svg/AllSvg";
import QuatationTable from "./QuatationTable";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardList } from "../../redux/dashboard/slice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import RippleLoader from "../../common/RippleLoader";

const Dashboard = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //Selector
  const { dashboardList, dashboardListLoading } = useSelector((store) => ({
    dashboardList: store?.dashboardData?.getDashboardListStatus?.data?.data,
    dashboardListLoading: store?.dashboardData?.loading,
  }));

  // dashboard list
  useEffect(() => {
    const formattedStartDate = moment(startDate).format("DD-MM-YYYY");
    const formattedEndDate = moment(endDate).format("DD-MM-YYYY");

    dispatch(
      getDashboardList({ from: formattedStartDate, to: formattedEndDate })
    );
  }, [startDate, endDate, dispatch]);
  return (
    <>
      <div>
        <div className="bg-secondary_black p-4 rounded-xl flex justify-between items-center">
          <p className="text-white text-xl font-normal">Dashboard</p>
          <div className="max-w-sm flex gap-3">
            <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              selectsStart
              id="date_time"
              placeholderText={"Date Range"}
              className="rounded-lg w-36 text-center border-0 py-2 focus:outline-none"
            />
            <ReactDatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              selectsEnd
              id="date_time"
              placeholderText={"Date Range"}
              className="rounded-lg w-36 text-center border-0 py-2 focus:outline-none"
            />
          </div>
        </div>
        {dashboardListLoading ? (
          <RippleLoader />
        ) : (
          <div>
            <div className="mt-5 grid grid-cols-2 gap-4">
              <div className="bg-light_green px-5 py-8 rounded-lg">
                <div className="flex gap-4">
                  <div className="pt-1">
                    <DollarVactor />
                  </div>
                  <div>
                    <p className="text-2xl text-light_grey font-normal">
                      Revenue
                    </p>
                    <p className="text-5xl font-normal text-green">
                      ${Number(dashboardList?.revenue).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-light_orange px-5 py-8 rounded-lg">
                <div className="flex gap-4">
                  <div className="pt-1">
                    <Calender />
                  </div>
                  <div>
                    <p className="text-2xl text-light_grey font-normal">
                      Total Booking
                    </p>
                    <p className="text-5xl font-normal text-light_grey">
                      {dashboardList?.bookings}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 bg-lightGrey  px-5 py-8 rounded-xl">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <Paper />
                  <p className="text-xl font-normal">Invoice</p>
                </div>
                <div>
                  <p
                    className="text-sm font-normal cursor-pointer text-disable_grey"
                    onClick={() => navigate("/sales")}
                  >
                    See all
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-3 mt-6">
                <div>
                  <p className="text-disable_grey text-sm font-normal">
                    Issued ({dashboardList?.issued_quotations?.count})
                  </p>
                  <p className="text-light_grey text-3xl font-normal">
                    $
                    {Number(dashboardList?.issued_quotations?.amount).toFixed(
                      2
                    )}
                  </p>
                </div>
                <div>
                  <p className="text-disable_grey text-sm font-normal">
                    Pending Payment ({dashboardList?.pending_payments?.count})
                  </p>
                  <p className="text-primaryLightGrey text-3xl font-normal">
                    $
                    {Number(dashboardList?.pending_payments?.amount).toFixed(2)}
                  </p>
                </div>
                <div>
                  <p className="text-disable_grey text-sm font-normal">
                    Paid ( ({dashboardList?.paid_payments?.count}))
                  </p>

                  <p className="text-green text-3xl font-normal">
                    ${Number(dashboardList?.paid_payments?.amount).toFixed(2)}{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-start p-4 space-y-4 md:space-y-0 md:space-x-4">
              <div className="bg-white rounded-lg shadow-md p-6 w-full ">
                <div className="flex justify-between mb-4">
                  <p className="text-2xl font-normal">
                    Quotation for Confirmation (
                    {dashboardList?.quotations_confirmation?.count})
                  </p>
                  <p
                    className="text-sm font-normal text-disable_grey cursor-pointer"
                    onClick={() => navigate("/sales")}
                  >
                    See All
                  </p>
                </div>
                <QuatationTable
                  quotation={dashboardList?.quotations_confirmation?.quotation}
                />
              </div>
              <div className=" bg-lightPink  px-5 py-8 rounded-xl w-full">
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    <SavedVector />
                    <p className="text-xl font-normal">Quotation</p>
                  </div>
                  <div>
                    <p
                      className="text-sm font-normal cursor-pointer text-disable_grey"
                      onClick={() => navigate("/sales")}
                    >
                      See all
                    </p>
                  </div>
                </div>
                <div className="flex justify-between mt-6">
                  <div>
                    <p className="text-disable_grey text-sm font-normal">
                      Issued ({dashboardList?.quotation?.issued?.count})
                    </p>
                    <p className="text-light_grey text-3xl font-normal">
                      ${" "}
                      {Number(dashboardList?.quotation?.issued?.amount).toFixed(
                        2
                      )}
                    </p>
                  </div>
                  <div>
                    <p className="text-disable_grey text-sm font-normal">
                      Pending Payment (
                      {dashboardList?.quotation?.cancelled?.count})
                    </p>
                    <p className="text-primary_orange text-3xl font-normal">
                      $
                      {Number(
                        dashboardList?.quotation?.cancelled?.amount
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
