import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputWithLabel } from "../../components/micro";
import { ResetLock } from "../../assets/svg/AllSvg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../redux/auth/slice";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <div className="main flex justify-center  items-center h-screen">
        <section className="w-full ">
          <div className="max-w-sm w-full mx-auto">
            <div className="flex items-center justify-center text-center">
              <ResetLock />
            </div>
            <div className="px-4">
              <div className="my-8 text-center ">
                <h1 className="sm:text-2xl text-2xl font-ebGaramond font-normal mb-1">
                  Reset Password
                </h1>
                <p className="text-base text-default_black mb-1  font-normal">
                  We will send you a link to reset your password via the email
                </p>
              </div>
              <Formik
                initialValues={{
                  email: "",
                  role: "admin",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email address")
                    .required("Please Enter Email"),
                })}
                onSubmit={(values) => {
                  setLoading(true);
                  dispatch(forgetPassword(values)).then((res) => {
                    setLoading(false);
                    if (res.type === "forgetPassword/fulfilled") {
                      navigate("/login");
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="space-y-5">
                      {/* Email */}
                      <InputWithLabel
                        className={
                          errors.email && touched.email
                            ? ` input-error `
                            : ` border `
                        }
                        idFromName="email"
                        label="Registered Email"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        errors={errors.email && touched.email}
                        errorsText={errors.email}
                      />
                      <button
                        type="submit"
                        className="w-full block text-white bg-mainthemeBlack text-center text-base border-2 border-transparent uppercase rounded transition duration-500 py-2  lg:px-7 px-4"
                      >
                        {loading ? "Loading...." : "SEND ME  RESET LINK"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ResetPassword;
