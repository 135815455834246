import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../assets/svg/AllSvg";

const Breadcrumb = (props) => {
  const {
    breadCrumbParent,
    breadCrumbParent2,
    breadCrumbParent3,
    breadCrumbActive,
    breadCrumbTitleKey,
    breadCrumbParentKey2,
    breadCrumbParentKey3,
    breadCrumbActiveKey,
    classes,
  } = props;
  return (
    <>
      <div>
        <ul
          className={`flex items-center overflow-x-auto gap-x-2 whitespace-nowrap ${classes}`}
        >
          {breadCrumbParent ? (
            <>
              <li>
                <Link
                  to={`${breadCrumbTitleKey ? breadCrumbTitleKey : "#"}`}
                  className={"opacity-70 lg:text-base text-sm"}
                >
                  {breadCrumbParent}
                </Link>
              </li>
            </>
          ) : (
            ""
          )}
          {breadCrumbParent2 ? (
            <>
              <li className="flex items-center justify-center gap-x-2">
                <span>
                  <Breadcrumbs size="16" />
                </span>
                <Link
                  to={`${breadCrumbParentKey2 ? breadCrumbParentKey2 : "#"}`}
                  className={"opacity-70 lg:text-base text-sm"}
                >
                  {breadCrumbParent2}
                </Link>
              </li>
            </>
          ) : (
            ""
          )}
          {breadCrumbParent3 ? (
            <>
              <li className="flex items-center justify-center gap-x-2">
                <span>
                  <Breadcrumbs size="16" />
                </span>
                <Link
                  to={`${breadCrumbParentKey3 ? breadCrumbParentKey3 : "#"}`}
                  className={"opacity-70 lg:text-base text-sm"}
                >
                  {breadCrumbParent3}
                </Link>
              </li>
            </>
          ) : (
            ""
          )}
          {breadCrumbActive ? (
            <>
              <li className="flex items-center justify-center gap-x-2 pointer-events-none">
                <span>
                  <Breadcrumbs size="16" />
                </span>
                <Link
                  className={"lg:text-base text-sm"}
                  to={`${breadCrumbActiveKey ? breadCrumbActiveKey : "#"}`}
                >
                  {breadCrumbActive}
                </Link>
              </li>
            </>
          ) : (
            ""
          )}
        </ul>
      </div>
    </>
  );
};

export default Breadcrumb;
