import React from "react";
import RippleLoader from "../../common/RippleLoader";
import DataNotFound from "../../common/DataNotFound";
import TableShowLimit from "../../common/TableShowLimit";
import Pagination from "../../common/Pagination";

const CustomTable = ({
  columnHeaders,
  dataRows,
  data,
  loading,
  paginationCurrentLimit,
  setPaginationCurrentLimit,
  total,
  current,
  paginationData,
  showPagination,
  overflowVisibleLg,
  shadowShow,
  backgroundColor,
  lastPage,
}) => {
  return (
    <>
      <div className="w-full rounded-lg">
        {loading ? (
          <>
            <RippleLoader />
          </>
        ) : (
          <>
            {data?.data?.length || data?.length > 0 ? (
              <>
                <div
                  className={` rounded-lg ${
                    overflowVisibleLg && "lg:overflow-visible"
                  } overflow-auto  ${
                    backgroundColor ? " bg-Grey" : "bg-white"
                  }`}
                >
                  <table className="w-full text-sm bg-white lg:overflow-visible overflow-x-auto !overflow-y-visible min-w-[400px] custom-scroll">
                    {/* Table Header */}
                    <thead className="text-sm text-primary_black bg-lightGrey font-semibold text-start">
                      <tr>{columnHeaders}</tr>
                    </thead>

                    {/* Table Body */}
                    <tbody className="text-sm font-normal">{dataRows}</tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <DataNotFound />
              </>
            )}
            {/* Pagination  */}
            {showPagination && (
              <div className="flex flex-wrap items-center justify-end my-2 ">
                <TableShowLimit
                  paginationCurrentLimit={paginationCurrentLimit}
                  setPaginationCurrentLimit={setPaginationCurrentLimit}
                />
                <p className="text-xs font-Proxima">
                  {" "}
                  {(data?.current_page - 1) * data?.per_page + 1} -{" "}
                  {(data?.current_page - 1) * data?.per_page +
                    data?.data?.length}{" "}
                  of {total}
                </p>
                <div className="px-4 py-2 rounded-lg ">
                  <Pagination
                    total={total}
                    lastPage={lastPage}
                    current={current}
                    pagination={paginationData}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CustomTable;
