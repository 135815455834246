import React, { useEffect, useState } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import { AddButton, Badge } from "../../components/micro";
import { useNavigate } from "react-router-dom";
import {
  SettingVector,
  SortDownArrow,
  TickVector,
} from "../../assets/svg/AllSvg";
import Action from "../../common/Action";
import DebounceSearch from "../../common/DebounceSearch";
import CustomTable from "../../components/customtable/CustomTable";
import ReactSelect from "react-select";
import ConfirmationModel from "../../common/ConfirmationModel";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSales,
  getcompanyList,
  getSalesList,
  sendEmail,
  updatesalesStatus,
} from "../../redux/sales/slice";
import { toast } from "react-toastify";

// Status Option
const Status = [
  { value: "cash", label: "Cash" },
  { value: "bank_transfer", label: "Bank Transfer" },
  { value: "paynow", label: "PayNow" },
  { value: "cheque", label: "Cheque" },
];

// Tab Option
const tabs = ["all", "paid", "unpaid", "cancelled"];

const Sales = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [allCompanyArray, setAllCompanyListArray] = useState([]);
  const [allChecked, setAllChecked] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [activeTabs, setActiveTabs] = useState("all");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [customersListCheck, setCustomersListCheck] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // selector
  const { salesList, salesListLoading, companyList, companyListLoading } =
    useSelector((store) => ({
      salesList: store?.salesData?.getSalesListStatus?.data,
      salesListLoading: store?.salesData?.loading,
      // Comapny List
      companyList: store?.salesData?.getcompanyListStatus?.data,
      companyListLoading: store?.salesData?.getcompanyListStatus?.loading,
    }));
  //Get sales List
  useEffect(() => {
    dispatch(
      getSalesList({
        search: searchValue,
        pageCount: pageCount,
        pageLimit: pageLimit,
        company_name: selectedCompany?.value ?? "",
        deposit_payment_method: selectedStatus?.value ?? "",
        sort_by: sortBy,
        status: activeTabs === "all" ? "" : activeTabs ?? "",
        sort_direction: sortDirection === "asc" ? "asc" : "desc",
      })
    ).then((response) => {
      if (response?.payload?.data?.last_page) {
        setLastPage(response.payload.data.last_page);
      }
    });
  }, [
    dispatch,
    searchValue,
    pageCount,
    pageLimit,
    selectedStatus,
    selectedCompany,
    activeTabs,
    sortBy,
    sortDirection,
  ]);

  // company list
  useEffect(() => {
    dispatch(getcompanyList());
  }, [dispatch]);

  // Set Company List Data
  useEffect(() => {
    if (companyList?.data) {
      let data = companyList?.data?.map((e) => ({ label: e, value: e }));
      setAllCompanyListArray(data);
    }
  }, [companyList]);

  //update status
  const updateStatus = (id, status, payment_status) => {
    const payload = {
      status: status,
      payment_status: payment_status,
    };
    dispatch(updatesalesStatus({ id: id, data: payload })).then((res) => {
      if (res?.type === "updatesalesStatus/fulfilled") {
        dispatch(
          getSalesList({
            search: searchValue,
            pageCount: pageCount,
            pageLimit: pageLimit,
            company_name: selectedCompany?.value ?? "",
            deposit_payment_method: selectedStatus?.value ?? "",
            sort_by: sortBy,
            status: activeTabs === "all" ? "" : activeTabs ?? "",
            sort_direction: sortDirection === "asc" ? "asc" : "desc",
          })
        );
      }
    });
  };
  // Filter Of selected Sales
  useEffect(() => {
    const checked = customersListCheck.filter((item) => item.checked);
    const checkedId = checked?.map((e) => e.id);
    setAllChecked(checkedId);
  }, [customersListCheck]);

  // handle Bulk Action
  const handleBulkAction = () => {
    setIsLoading(true);
    if (allChecked?.length) {
      dispatch(deleteSales({ id: allChecked })).then((res) => {
        setIsLoading(false);
        setConfirmDelete(false);
        if (res.type === "deleteSales/fulfilled") {
          dispatch(
            getSalesList({
              search: searchValue,
              pageCount: pageCount,
              pageLimit: pageLimit,
              company_name: selectedCompany?.value ?? "",
              deposit_payment_method: selectedStatus?.value ?? "",
              sort_by: sortBy,
              // activeTabs: activeTabs,
              status: activeTabs === "all" ? "" : activeTabs ?? "",
              sort_direction: sortDirection === "asc" ? "asc" : "desc",
            })
          );
        }
      });
    }
  };

  // Set Sales is check false
  useEffect(() => {
    if (salesList?.data?.data) {
      const updatedList = salesList?.data?.data?.map((item) => ({
        ...item,
        checked: false,
      }));
      setCustomersListCheck(updatedList);
    }
  }, [salesList]);

  // Select All Sales
  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const updatedItems = customersListCheck?.map((item) => ({
      ...item,
      checked: newSelectAll,
    }));
    setCustomersListCheck(updatedItems);
  };

  // Select One Sales
  const handleIndividualChange = (id) => {
    const updatedItems = customersListCheck?.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setCustomersListCheck(updatedItems);

    const allChecked = updatedItems.every((item) => item.checked);
    setSelectAll(allChecked);
  };

  // Send Email
  const handleSendEmail = (id) => {
    dispatch(sendEmail({ id })).then((response) => {
      if (response.type === "sendEmail/fulfilled") {
        navigate("/sales");
      }
    });
  };

  // Table Header
  const ColumnHeaders = () => (
    <>
      <th
        scope="col"
        className="p-2 font-Proxima sm:sm:text-sm text-xs font-medium py-4 px-4 text-start"
      >
        <div className="flex items-center">
          <div className="flex items-center">
            <input
              type="checkbox"
              className="w-4 h-4 mr-2 accent-brown"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />{" "}
            <span>No.</span>
          </div>
        </div>
      </th>
      <th
        scope="col"
        className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start"
      >
        <div className="flex items-center space-x-2">
          <span>Name</span>
          <button
            onClick={() => {
              setSortBy("name");
              setSortDirection(sortDirection === "desc" ? "asc" : "desc");
            }}
          >
            <SortDownArrow className="transition-transform duration-300 " />
          </button>
        </div>
      </th>
      <th
        scope="col"
        className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start"
      >
        <div className="flex items-center space-x-2">
          <span>Company</span>
          <button
            onClick={() => {
              setSortBy("company_name");
              setSortDirection(sortDirection === "desc" ? "asc" : "desc");
            }}
          >
            <SortDownArrow className="transition-transform duration-300 " />
          </button>
        </div>
      </th>
      <th
        scope="col"
        className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start"
      >
        <div className="flex items-center">
          <span>Quotation ID</span>
          <button
            onClick={() => {
              setSortBy("quotation_id");
              setSortDirection(sortDirection === "desc" ? "asc" : "desc");
            }}
          >
            <SortDownArrow className="transition-transform duration-300 " />
          </button>
        </div>
      </th>
      <th
        scope="col"
        className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start"
      >
        <div className="flex items-center space-x-2">
          <span>Invoice ID</span>
          <button
            onClick={() => {
              setSortBy("invoice_id");
              setSortDirection(sortDirection === "desc" ? "asc" : "desc");
            }}
          >
            <SortDownArrow className="transition-transform duration-300 " />
          </button>
        </div>
      </th>
      <th
        scope="col"
        className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start"
      >
        <div className="flex items-center space-x-2">
          <span>Deposit</span>
          <button
            onClick={() => {
              setSortBy("deposit_payment_method");
              setSortDirection(sortDirection === "desc" ? "asc" : "desc");
            }}
          >
            <SortDownArrow className="transition-transform duration-300 " />
          </button>
        </div>
      </th>
      <th
        scope="col"
        className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start"
      >
        <div className="flex items-center space-x-2">
          <span>Amount</span>
          <button
            onClick={() => {
              setSortBy("amount");
              setSortDirection(sortDirection === "desc" ? "asc" : "desc");
            }}
          >
            <SortDownArrow className="transition-transform duration-300 " />
          </button>
        </div>
      </th>
      <th
        scope="col"
        className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start"
      >
        Status
      </th>
      <th className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start">
        Action
      </th>
    </>
  );

  // Table body
  const DataRows = () => (
    <>
      {(customersListCheck || [])?.map((element, index) => (
        <tr
          key={index}
          className="font-Proxima shadow-sm hover:bg-shadedgrey border-b "
        >
          <td className="px-5 py-3">
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={element?.checked}
                className="w-4 h-4 mr-2 accent-brown"
                onChange={() => handleIndividualChange(element?.id)}
              />
              {(salesList?.data?.current_page - 1) * salesList?.data?.per_page +
                index +
                1}
            </div>
          </td>
          <td className="px-5 py-3 truncate sm:text-sm text-xs">
            {element?.name ?? "-"}
          </td>
          <td className="px-5 py-3 truncate sm:text-sm text-xs">
            {element?.company_name ?? "-"}
          </td>
          <td className="px-5 py-3 truncate sm:text-sm text-xs">
            {element?.quotation_id ?? "-"}
          </td>
          <td className="px-5 py-3 truncate sm:text-sm text-xs">
            {element?.payment_status === "paid" ||
            (element?.status === "paid" &&
              (element?.status === "paid" || element?.status === "cancelled"))
              ? element?.invoice_id
              : "-"}
          </td>
          <td className="px-5 py-3 truncate sm:text-sm text-xs ">
            {element?.deposit_payment_method
              ? element.deposit_payment_method === "bank_transfer"
                ? "Bank Transfer"
                : element.deposit_payment_method === "cash"
                ? "Cash"
                : element.deposit_payment_method === "paynow"
                ? "PayNow"
                : element.deposit_payment_method === "cheque"
                ? "Cheque"
                : element.deposit_payment_method
              : "-"}
          </td>
          <td className="px-5 py-3 truncate sm:text-sm text-xs">
            {Number(element?.amount ?? "-").toFixed(2)}
          </td>
          <td className="px-5 py-3">
            <div className="flex justify-start">
              {element?.status === "cancelled" ? (
                <Badge
                  className={`capitalize ${
                    element?.payment_status === "paid"
                      ? "bg-green text-white"
                      : "bg-red text-white"
                  }`}
                  value={
                    <div className="flex items-center gap-1">
                      {element?.payment_status === "paid" && <TickVector />}
                      {element?.payment_status === "unpaid"
                        ? "Cancelled"
                        : "Cancelled"}
                    </div>
                  }
                />
              ) : (
                <Badge
                  className={`capitalize ${
                    element?.status === "pending"
                      ? "bg-medium_grey text-primary_black "
                      : element?.status === "paid"
                      ? "bg-green text-white"
                      : element?.status === "unpaid"
                      ? "bg-orange text-white"
                      : element?.status === "cancelled"
                      ? "bg-red text-white"
                      : ""
                  }`}
                  value={
                    element?.status === "pending"
                      ? "To Be Advise"
                      : element?.status ?? "-"
                  }
                />
              )}
            </div>
          </td>
          {/* ACTION MENU */}
          <td className="flex px-5 py-3">
            <Action
              optionList={
                <>
                  {/* View/Edit Options */}
                  <li
                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                    onClick={() =>
                      navigate("/view-sales", {
                        state: { mode: "edit", sales: element },
                      })
                    }
                  >
                    View/Edit
                  </li>

                  {/* Conditional Actions Based on Status */}
                  {element?.status === "pending" && (
                    <>
                      <li
                        className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                        onClick={() => {
                          updateStatus(element?.id, "unpaid", "unpaid");
                        }}
                      >
                        Accepted
                      </li>
                      <li
                        className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                        onClick={() => {
                          setAllChecked([element.id]);
                          setConfirmDelete(true);
                        }}
                      >
                        Delete
                      </li>
                    </>
                  )}
                  {element.status === "unpaid" && (
                    <>
                      <li
                        className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                        onClick={() => {
                          updateStatus(element?.id, "paid", "paid");
                        }}
                      >
                        Paid
                      </li>
                      <li
                        className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                        onClick={() => {
                          handleSendEmail(element?.id);
                        }}
                      >
                        Send Email
                      </li>
                      <li
                        className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                        onClick={() =>
                          navigate("/view-sales", {
                            state: { mode: "cancel", sales: element },
                          })
                        }
                      >
                        Cancel
                      </li>
                    </>
                  )}
                  {element.status === "paid" && (
                    <>
                      <li
                        className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                        onClick={() => {
                          updateStatus(element?.id, "unpaid", "unpaid");
                        }}
                      >
                        Unpaid
                      </li>
                      <li
                        className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                        onClick={() => {
                          handleSendEmail(element?.id);
                        }}
                      >
                        Send Email
                      </li>
                    </>
                  )}
                  {element.status === "cancelled" &&
                    element.payment_status == "unpaid" && (
                      <li
                        className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                        onClick={() => {
                          updateStatus(element?.id, "cancelled", "paid");
                        }}
                      >
                        Paid Cancellation
                      </li>
                    )}
                  {element.status === "cancelled" &&
                    element.payment_status == "paid" && (
                      <>
                        <li
                          className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                          onClick={() => {
                            setAllChecked([element.id]);
                            setConfirmDelete(true);
                          }}
                        >
                          Delete
                        </li>
                        <li
                          className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                          onClick={() => {
                            updateStatus(element?.id, "cancelled", "unpaid");
                          }}
                        >
                          UnPaid Cancellation
                        </li>
                      </>
                    )}
                </>
              }
            />
          </td>
        </tr>
      ))}
    </>
  );

  return (
    <>
      <div className="space-y-5">
        <div className="mb-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <SettingVector />
              <h6 className="font-opensans font-normal sm:text-xl text-lg ">
                Sales
              </h6>
            </div>
            <AddButton
              value="Add Sale"
              onClick={() =>
                navigate("/create-edit-sales", {
                  state: { mode: "create" },
                })
              }
            />
          </div>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={"Sales"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
      </div>
      <div>
        <div className="my-4">
          <div className={`flex border border-darkGrey rounded`}>
            {tabs?.map((tab) => (
              <button
                key={tab}
                className={`py-2 px-4 uppercase text-center text-base text-mainthemeBlack font-normal w-full border-r border-darkGrey transition duration-300 ${
                  activeTabs === tab
                    ? "bg-lightGrey"
                    : "border-r border-darkGrey"
                } w-full font-normal  h-full text-mainthemeBlack`}
                onClick={() => setActiveTabs(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
        <div className="flex gap-2 items-center lg:space-x-3 p-4 bg-white rounded flex-wrap lg:flex-nowrap">
          {/* Bulk Option for Sales */}
          <ReactSelect
            className={"w-full max-w-52 text-black p-0 font-Proxima"}
            id="option"
            placeholder="Bulk Action"
            options={[{ value: "delete", label: "Delete" }]}
            isClearable={true}
            value={selectedType}
            onChange={(e) => {
              if (allChecked?.length) {
                setSelectedType(e);
                setConfirmDelete(true);
              } else {
                toast.error("Please Select Sales");
              }
            }}
            styles={{
              control: (base) => ({
                ...base,
                border: `1px solid #0000003B`,
                borderColor: selectedType ? "#8F6500" : "#0000003B",
                borderRadius: "0.3rem",
                paddingTop: "2px",
                paddingBottom: "2px",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #0000003B",
                },
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#8F6500" : "white",
                "&:active": {
                  backgroundColor: state.isSelected
                    ? "#8F6500"
                    : "rgba(0, 0, 0, 0.04)",
                },
              }),
            }}
          />
          {/* Company Filter for Sales  */}
          <ReactSelect
            className={"w-full max-w-52 text-black p-0 font-Proxima"}
            id="company"
            placeholder="Company"
            options={allCompanyArray}
            isClearable={true}
            isLoading={companyListLoading}
            getOptionValue={(selectedComapny) => selectedComapny?.value}
            getOptionLabel={(selectedComapny) => selectedComapny?.label}
            onChange={(e) => {
              setSelectedCompany(e);
            }}
            styles={{
              control: (base) => ({
                ...base,
                border: `1px solid #0000003B`,
                borderColor: selectedType ? "#8F6500" : "#0000003B",
                borderRadius: "0.3rem",
                paddingTop: "2px",
                paddingBottom: "2px",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #0000003B",
                },
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#8F6500" : "white",
                "&:active": {
                  backgroundColor: state.isSelected
                    ? "#8F6500"
                    : "rgba(0, 0, 0, 0.04)",
                },
              }),
            }}
          />
          {/* Status Filter for Sales  */}
          <ReactSelect
            className={"w-full max-w-62 text-black p-0 font-Proxima"}
            id="status"
            placeholder="Deposite Payment Method"
            getOptionValue={(selectedStatus) => selectedStatus?.value}
            getOptionLabel={(selectedStatus) => selectedStatus?.label}
            options={Status}
            isClearable={true}
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e);
            }}
            styles={{
              control: (base) => ({
                ...base,
                border: `1px solid #0000003B`,
                borderColor: selectedStatus ? "#8F6500" : "#0000003B",
                borderRadius: "0.3rem",
                paddingTop: "2px",
                paddingBottom: "2px",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #0000003B",
                },
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#8F6500" : "white",
                "& :active": {
                  backgroundColor: state.isSelected
                    ? "#8F6500"
                    : "rgba(0, 0, 0, 0.04)",
                },
              }),
            }}
          />
          <DebounceSearch
            className="full-width"
            search={searchValue}
            handleChange={setSearchValue}
            placeholder={"Search..."}
          />
        </div>
        <div className="w-full bg-white shadow rounded-lg mt-5 ">
          <CustomTable
            overflowVisibleLg={true}
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            data={salesList?.data}
            loading={salesListLoading}
            showPagination={true}
            setPaginationCurrentLimit={setPageLimit}
            paginationCurrentLimit={pageLimit}
            total={salesList?.data?.total}
            current={pageCount}
            lastPage={lastPage}
            paginationData={(crPage) => setPageCount(crPage)}
          />
        </div>
      </div>
      {confirmDelete && (
        <ConfirmationModel
          isLoading={isLoading}
          confirmText={"Delete"}
          onClose={() => {
            setConfirmDelete(false);
          }}
          onConfirm={() => {
            handleBulkAction();
          }}
        />
      )}
    </>
  );
};

export default Sales;
