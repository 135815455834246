// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  getPackageListStatus: { data: null },
  getPackageStatus: { data: null },
  updatePackageStatusStatus: { data: null },
  createPackagesStatus: { data: null },
  deletePackageStatus: { data: null },
};
