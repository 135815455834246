import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Get Package List
export const getPackageList = createAsyncThunk(
  "getPackageList",
  async (
    { pageLimit, pageCount, sort_by, sort_direction, status },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `packages?&sort_by=${sort_by}&sort_direction=${sort_direction}&page=${pageCount}&limit=${pageLimit}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Package
export const getPackage = createAsyncThunk(
  "getPackage",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`/packages/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Edit Package
export const createPackages = createAsyncThunk(
  "createPackages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`packages`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// update Package Status
export const updatePackageStatus = createAsyncThunk(
  "updatePackageStatus",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`packages/${id}`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Package
export const deletePackage = createAsyncThunk(
  "deletePackage",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`/packages/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const packagesSlice = createSlice({
  name: "packages",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // get Package List
      .addCase(getPackageList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPackageList.fulfilled, (state, action) => {
        state.loading = false;
        state.getPackageListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getPackageList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Get Package
      .addCase(getPackage.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPackage.fulfilled, (state, action) => {
        state.loading = false;
        state.getPackageStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getPackage.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // Create Edit Package
      .addCase(createPackages.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPackages.fulfilled, (state, action) => {
        state.loading = false;
        state.createPackagesStatus.data = action.payload;
        state.error = false;
      })
      .addCase(createPackages.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // Delete Package
      .addCase(deletePackage.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePackage.fulfilled, (state, action) => {
        state.loading = false;
        state.deletePackageStatus.data = action.payload;
        state.error = false;
      })
      .addCase(deletePackage.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // update Package Status
      .addCase(updatePackageStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePackageStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.updatePackageStatusStatus.data = action.payload;
        state.error = false;
      })
      .addCase(updatePackageStatus.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
const { reducer } = packagesSlice;
export default reducer;
