import React, { createContext, useEffect, useState } from "react";
// import { adminGetProfileData } from "../redux/auth/slice";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import RippleLoader from "../common/RippleLoader";
import { Provider } from "./layoutContext";

export const AdminContext = createContext({});

const MainLayout = () => {
  const [isAsideOpen, setIsAsideOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [windowScreenWidth, setwindowScreenWidth] = useState(window.innerWidth);

  const getAccessToken = sessionStorage.getItem("accessToken");

  useEffect(() => {
    function handleResize() {
      setwindowScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    windowScreenWidth < 767 && setIsAsideOpen(false);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowScreenWidth]);

  return (
    <div className={`bg-lightyellow ${isLoading ? "h-screen" : ""}`}>
      {getAccessToken ? (
        <AdminContext.Provider value={{}}>
          <Provider value={{ isAsideOpen, setIsAsideOpen }}>
            {isLoading ? (
              <RippleLoader />
            ) : (
              <div
                className={`h-screen flex flex-col min-w-0 flex-1 overflow-y-hidden overflow-x-hidden`}
              >
                <Header />
                <div className="flex">
                  <Sidebar />
                  <div className="h-[calc(100vh-4rem)] overflow-y-auto bg-primaryWhite w-full px-6 py-8">
                    <Outlet />
                  </div>
                </div>
              </div>
            )}
          </Provider>
        </AdminContext.Provider>
      ) : (
        <>
          <Navigate to={"/login"} replace />
        </>
      )}
    </div>
  );
};

export default MainLayout;
