// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  getSalesListStatus: { data: null },
  getcompanyListStatus: { data: null },
  getOneSalesStatus: { data: null },
  updateSalesStatusStatus: { data: null },
  createSalesStatus: { data: null },
  deleteSalesStatus: { data: null },
  sendEmailStatus: { data: null },
};
