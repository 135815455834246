import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Breadcrumb from "../../common/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { DollarVactor } from "../../assets/svg/AllSvg";
import {
  DefaultButton,
  DefaultPrimaryButton,
  InputError,
  InputWithLabel,
  SelectBox,
} from "../../components/micro";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getCountryData } from "../../redux/address/slice";
import { createSales, getOneSales } from "../../redux/sales/slice";
import ConfirmationModel from "../../common/ConfirmationModel";
import RippleLoader from "../../common/RippleLoader";
import Package from "./components/Package";
import ReactSelect from "react-select";
import moment from "moment/moment";

// Status Option
const Status = [
  { value: "cash", label: "Cash" },
  { value: "bank_transfer", label: "Bank Transfer" },
  { value: "paynow", label: "PayNow" },
  { value: "cheque", label: "Cheque" },
];
const CreateEditSales = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [countryArray, setCountryArray] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const [packagesArray, setPackagesArray] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isEditMode = location?.state?.mode;
  const salesData = location?.state?.sales;

  //  Selector
  const { getSalesData, loading, countryData, countryLoading } = useSelector(
    (store) => ({
      getSalesData: store?.salesData?.getOneSalesStatus?.data?.data,
      loading: store?.salesData?.loading,

      //Contry Data
      countryData: store?.addressData?.getCountryDataStatus?.data,
      countryLoading: store?.addressData?.getCountryDataStatus?.loading,
    })
  );
  // Get country
  useEffect(() => {
    if (!countryData?.length) {
      dispatch(getCountryData());
    }
  }, [dispatch]);

  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({
        ...x,
        label: `+${x?.id}`,
      }));
      setCountryArray(data);
    }
  }, [countryData]);

  // get sales
  useEffect(() => {
    if (salesData?.id) {
      dispatch(getOneSales(salesData?.id));
    }
  }, [salesData, dispatch]);

  useEffect(() => {
    if (salesData?.id && getSalesData?.packages?.length) {
      setPackagesArray(getSalesData?.packages);
    }
  }, [getSalesData?.packages, salesData]);

  // Submit Customer
  const handleConfirm = async () => {
    setIsLoading(true);
    const payload = {
      name: formValues?.name,
      company_name: formValues?.company,
      email: formValues?.email,
      phone: formValues?.mobile_number,
      phone_code: formValues?.phone_codeId,
      office_code: formValues?.office_codeId,
      office_number: formValues?.office_number,
      address: formValues?.address,
      postal_code: formValues?.postal_code,
      // performance_date_time: formattedDateTime,
      performance_date_time: moment(formValues?.performance_date_time).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      performance_address: formValues?.performance_address,
      performance_postal_code: formValues?.performance_postal_code,
      packages: packagesArray,
      deposit_payment_method: formValues?.payment,
    };
    dispatch(
      createSales(salesData?.id ? { id: salesData.id, ...payload } : payload)
    ).then((res) => {
      if (res.type === "createSales/fulfilled") {
        navigate("/view-sales", {
          state: { sales: res?.payload?.data, mode: "view" },
        });
      }
      setIsLoading(false);
      setShowConfirmationModal(false);
    });
  };

  // update package
  const updatePackageDetail = (
    index,
    package_name,
    description,
    price,
    no_of_items
  ) => {
    const updatePackage = packagesArray?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          package_id: package_name,
          description: description,
          price: price,
          no_of_items: no_of_items,
        };
      }
      return item;
    });
    setPackagesArray(updatePackage);
    setIsDisable(false);
  };

  // remove package
  const removePackges = (index) => {
    setPackagesArray([
      ...packagesArray.slice(0, index),
      ...packagesArray.slice(index + 1),
    ]);
  };

  return (
    <>
      <div className="space-y-5">
        <div className="mb-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <DollarVactor />
              <h6 className="font-opensans font-normal sm:text-xl text-lg ">
                Create Sales
              </h6>
            </div>
          </div>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={
              isEditMode === "edit" ? "Edit Sales" : "Create Sales"
            }
            breadCrumbParent2={"Sales"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h6 className="font-Montserrat font-medium text-xl mb-3">
            {isEditMode === "edit" ? "Edit Sales" : "Create Sales"}{" "}
          </h6>
          <p className="text-sm font-normal pb-4">Billing Information</p>
          {loading ? (
            <RippleLoader />
          ) : (
            <Formik
              enableReinitialize={false}
              initialValues={{
                name:
                  formValues?.name ?? (salesData?.id ? getSalesData?.name : ""),
                company:
                  formValues?.company ??
                  (salesData?.id ? getSalesData?.company_name : ""),
                email:
                  formValues?.email ??
                  (salesData?.id ? getSalesData?.email : ""),
                phone_code:
                  formValues?.phone_code ??
                  (salesData?.id ? getSalesData?.phone_code : ""),
                office_code:
                  formValues?.office_code ??
                  (salesData?.id ? getSalesData?.office_code : ""),
                phone_codeId:
                  formValues?.phone_codeId ??
                  (salesData?.id ? Number(getSalesData?.phone_code) : ""),
                office_codeId:
                  formValues?.office_codeId ??
                  (salesData?.id ? Number(getSalesData?.office_code) : ""),
                office_number:
                  formValues?.office_number ??
                  (salesData?.id ? getSalesData?.office_number : ""),
                mobile_number:
                  formValues?.mobile_number ??
                  (salesData?.id ? getSalesData?.phone : ""),
                address:
                  formValues?.address ??
                  (salesData?.id ? getSalesData?.address : ""),
                postal_code:
                  formValues?.postal_code ??
                  (salesData?.id ? getSalesData?.postal_code : ""),
                performance_date_time:
                  formValues?.performance_date_time ??
                  (salesData?.id
                    ? new Date(
                        getSalesData?.performance_date_time
                          ? getSalesData?.performance_date_time
                          : null
                      )
                    : ""),
                performance_address:
                  formValues?.performance_address ??
                  (salesData?.id ? getSalesData?.performance_address : ""),
                performance_postal_code:
                  formValues?.performance_postal_code ??
                  (salesData?.id ? getSalesData?.performance_postal_code : ""),
                packages:
                  formValues?.packages ??
                  (packagesArray?.length ? packagesArray : []),
                payment:
                  formValues?.payment ??
                  (salesData?.id ? getSalesData?.deposit_payment_method : ""),
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Name is required"),
                company: Yup.string().required("company is required"),
                phone_codeId: Yup.string().required("Please Select Country"),
                office_codeId: Yup.string().required("Please Select Country"),
                office_number: Yup.string()
                  .required("please Enter Mobile")
                  .min(8, "Mobile is too short - should be 8 chars minimum."),
                mobile_number: Yup.string()
                  .required("please Enter Mobile")
                  .min(8, "Mobile is too short - should be 8 chars minimum."),
                email: Yup.string()
                  .email("Invalid email")
                  .required("Email is required"),
                address: Yup.string().min(3).required("Please Enter Address"),
                postal_code: Yup.string()
                  .required("Please Enter Postal Code")
                  .min(5, "Postal Code must be at least 5 characters")
                  .matches(/^\d+$/, "Postal Code must be numeric"),
                performance_address: Yup.string()
                  .min(3)
                  .required("Please Enter Performance Address"),
                performance_postal_code: Yup.string()
                  .required("Please Enter Postal Code")
                  .min(5, "Postal Code must be at least 5 characters")
                  .matches(/^\d+$/, "Postal Code must be numeric"),
                performance_date_time: Yup.string()
                  .nullable()
                  .required("Please Select Performance Date Time"),
                packages: Yup.array().min(0).required("Package is required"),
              })}
              onSubmit={(values) => {
                setFormValues(values);
                setShowConfirmationModal(true);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <div className="sm:grid grid-cols-2  gap-6 mb-4">
                      {/* Name */}
                      <div className="sm:mb-0 mb-2">
                        <InputWithLabel
                          className={`${"text-black border"}  ${
                            errors.name && touched.name
                              ? `border border-red`
                              : `border `
                          }  transition duration-300 ease-in-out `}
                          idFromName="name"
                          label="Name"
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                            setIsDisable(false);
                          }}
                          onBlur={handleBlur}
                          value={values.name}
                          errors={errors.name && touched.name}
                          errorsText={errors.name}
                        />
                      </div>
                      {/* company */}
                      <div className="sm:mb-0 mb-4">
                        <InputWithLabel
                          className={`${"text-black border "}  ${
                            errors.company && touched.company
                              ? `border border-red`
                              : `border `
                          }  transition duration-300 ease-in-out`}
                          idFromName="company"
                          label="Company"
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                            setIsDisable(false);
                          }}
                          onBlur={handleBlur}
                          value={values.company}
                          errors={errors.company && touched.company}
                          errorsText={errors.company}
                        />
                      </div>
                      <div className="gap-3">
                        <div className="sm:grid grid-cols-3 gap-2">
                          {/* Country */}
                          {/* office code */}
                          <div className="sm:mb-0 mb-2">
                            <div
                              className={`${"text-black "}  ${
                                errors.office_codeId && touched.office_codeId
                                  ? ` border-red border`
                                  : ` border-borderTheme/80`
                              } relative bg-white transition duration-300 rounded plus-number-inputs`}
                            >
                              <SelectBox
                                id="office_codeId"
                                placeholder="Country"
                                isLoading={countryLoading}
                                options={countryArray}
                                getOptionLabel={(countryArray) =>
                                  countryArray.label
                                }
                                getOptionValue={(countryArray) =>
                                  countryArray.id
                                }
                                value={countryArray.find(
                                  (country) =>
                                    country.phonecode === values.office_codeId
                                )}
                                onChange={(e) => {
                                  setFieldValue("office_code", e);
                                  setFieldValue("office_codeId", e?.phonecode);
                                  setIsDisable(false);
                                }}
                              />
                              <label
                                htmlFor="country"
                                className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                              >
                                Country
                              </label>
                            </div>
                            {errors.office_codeId && touched.office_codeId ? (
                              <InputError errorTitle={errors.office_codeId} />
                            ) : null}
                          </div>
                          {/* Phone */}
                          <div className="col-span-2 sm:mb-0 mb-3">
                            <InputWithLabel
                              className={`${"text-black border "}  ${
                                errors.office_number && touched.office_number
                                  ? `border border-red`
                                  : `border `
                              }  transition duration-300 ease-in-out`}
                              idFromName="office_number"
                              label="Office Number"
                              type={"numeric"}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  handleChange(e);
                                  setIsDisable(false);
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.office_number}
                              errors={
                                errors.office_number && touched.office_number
                              }
                              errorsText={errors.office_number}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid grid-cols-3 gap-2">
                        {/* Country */}
                        <div className="sm:mb-0 mb-2">
                          <div
                            className={`${"text-black "}  ${
                              errors.phone_codeId && touched.phone_codeId
                                ? ` border-red border`
                                : ` border-borderTheme/80`
                            } relative bg-white transition duration-300 rounded plus-number-inputs`}
                          >
                            <SelectBox
                              id="phone_codeId"
                              placeholder="Country"
                              isLoading={countryLoading}
                              options={countryArray}
                              getOptionValue={(countryArray) => countryArray.id}
                              getOptionLabel={(countryArray) =>
                                countryArray.label
                              }
                              value={countryArray.find(
                                (country) =>
                                  country.phonecode === values.phone_codeId
                              )}
                              onChange={(e) => {
                                setFieldValue("phone_code", e);
                                setFieldValue("phone_codeId", e?.phonecode);
                                setIsDisable(false);
                              }}
                            />
                            <label
                              htmlFor="country"
                              className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                            >
                              Country
                            </label>
                          </div>
                          {errors.phone_codeId && touched.phone_codeId ? (
                            <InputError errorTitle={errors.phone_codeId} />
                          ) : null}
                        </div>
                        {/* Phone */}
                        <div className="col-span-2 sm:mb-0 mb-2">
                          <InputWithLabel
                            className={`${"text-black border "}  ${
                              errors.mobile_number && touched.mobile_number
                                ? `border border-red`
                                : `border `
                            }  transition duration-300 ease-in-out`}
                            idFromName="mobile_number"
                            label="Mobile Number"
                            type={"numeric"}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*$/.test(value)) {
                                handleChange(e);
                                setIsDisable(false);
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.mobile_number}
                            errors={
                              errors.mobile_number && touched.mobile_number
                            }
                            errorsText={errors.mobile_number}
                          />
                        </div>
                      </div>
                      <div className=" sm:mb-0 mb-2 ">
                        {/* Email */}
                        <InputWithLabel
                          className={`${"text-black border "}  ${
                            errors.email && touched.email
                              ? `border border-red`
                              : `border `
                          }  transition duration-300 ease-in-out`}
                          idFromName="email"
                          label="Email"
                          type="email"
                          onChange={(e) => {
                            handleChange(e);
                            setIsDisable(false);
                          }}
                          onBlur={handleBlur}
                          value={values.email}
                          errors={errors.email && touched.email}
                          errorsText={errors.email}
                        />
                      </div>
                      <div>
                        <ReactSelect
                          className={
                            "w-full max-w-full text-black p-0 font-Proxima"
                          }
                          id="status"
                          placeholder="Deposit Payment Method"
                          options={Status}
                          value={Status?.find(
                            (op) => op.value === values.payment
                          )}
                          onChange={(e) => {
                            setFieldValue("payment", e.value);
                            setIsDisable(false);
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              border: `1px solid #0000003B`,
                              borderColor: "#0000003B",
                              borderRadius: "0.3rem",
                              paddingTop: "2px",
                              paddingBottom: "2px",
                              boxShadow: "none",
                              "&:hover": {
                                border: "1px solid #0000003B",
                              },
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isSelected
                                ? "#8F6500"
                                : "white",
                              "& :active": {
                                backgroundColor: state.isSelected
                                  ? "#8F6500"
                                  : "rgba(0, 0, 0, 0.04)",
                              },
                            }),
                          }}
                        />
                      </div>
                      <div className="sm:mb-0 mb-2">
                        {/* Adddress */}
                        <InputWithLabel
                          className={`${"text-black border "}  ${
                            errors.address && touched.address
                              ? `border border-red`
                              : `border `
                          }  transition duration-300 ease-in-out`}
                          idFromName="address"
                          label="Address*"
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                            setIsDisable(false);
                          }}
                          onBlur={handleBlur}
                          value={values.address}
                          errors={errors.address && touched.address}
                          errorsText={errors.address}
                        />
                      </div>
                      <div>
                        {/* postal code */}
                        <InputWithLabel
                          className={`text-black border   ${
                            errors.postal_code && touched.postal_code
                              ? `border border-red`
                              : `border `
                          }  transition duration-300 ease-in-out`}
                          idFromName="postal_code"
                          label="Postal Code*"
                          type="numeric"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                              handleChange(e);
                              setIsDisable(false);
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.postal_code}
                          errors={errors.postal_code && touched.postal_code}
                          errorsText={errors.postal_code}
                        />
                      </div>
                    </div>

                    {/* Performance Details */}
                    <div className="mb-3">
                      <p className="text-sm font-normal mt-6 mb-2">
                        Performance Details
                      </p>
                      <p className="mb-3 text-xs font-normal text-secondary_grey">
                        Performance Date & Time
                      </p>
                      {/*  Date */}
                      <div className="mb-3">
                        <div
                          className={`${
                            errors.performance_date_time &&
                            touched.performance_date_time
                              ? ` input-error `
                              : ` `
                          } relative bg-white transition duration-300 lg:text-base !sm:text-sm !text-base rounded plus-number-inputs`}
                        >
                          <ReactDatePicker
                            selected={values.performance_date_time}
                            onChange={(date) =>
                              setFieldValue("performance_date_time", date)
                            }
                            selectsStart
                            id="performance_date_time"
                            dateFormat="dd/MM/yyyy | hh:mm aa"
                            showTimeSelect
                            placeholderText={"Select Performance Date"}
                            className={`border border-secondary_grey ${
                              errors.performance_date_time &&
                              touched.performance_date_time
                                ? "error-forms"
                                : ""
                            } rounded w-full px-3 py-2.5`}
                          />
                          <label
                            htmlFor="performance_date_time"
                            className="absolute text-light_grey pointer-events-none sm:text-base text-sm bg-white duration-300 transform -translate-y-5 scale-75 top-2  origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                          >
                            Date & Time
                          </label>
                        </div>
                        {errors.performance_date_time &&
                        touched.performance_date_time ? (
                          <InputError
                            errorTitle={errors.performance_date_time}
                          />
                        ) : null}
                      </div>
                      <div>
                        <p className="mb-3 text-xs font-normal text-secondary_grey">
                          {" "}
                          Performance Address
                        </p>
                        <div className="sm:grid grid-cols-2 gap-6">
                          <div className="sm:mb-0 mb-2">
                            {/* Adddress */}
                            <InputWithLabel
                              className={`${"text-black border "}  ${
                                errors.performance_address &&
                                touched.performance_address
                                  ? `border border-red`
                                  : `border `
                              }  transition duration-300 ease-in-out`}
                              idFromName="performance_address"
                              label="Address*"
                              type="text"
                              onChange={(e) => {
                                handleChange(e);
                                setIsDisable(false);
                              }}
                              onBlur={handleBlur}
                              value={values.performance_address}
                              errors={
                                errors.performance_address &&
                                touched.performance_address
                              }
                              errorsText={errors.performance_address}
                            />
                          </div>
                          <div>
                            {/* postal code */}
                            <InputWithLabel
                              className={`${"text-black border "}  ${
                                errors.performance_postal_code &&
                                touched.performance_postal_code
                                  ? `border border-red`
                                  : `border `
                              }  transition duration-300 ease-in-out`}
                              idFromName="performance_postal_code"
                              label="Postal Code*"
                              type="numeric"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  handleChange(e);
                                  setIsDisable(false);
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.performance_postal_code}
                              errors={
                                errors.performance_postal_code &&
                                touched.performance_postal_code
                              }
                              errorsText={errors.performance_postal_code}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Packages */}
                    <h5 className="text-sm font-normal mb-3">Packages</h5>
                    <div className="space-y-4">
                      {packagesArray?.map((data, index) => {
                        return (
                          <Package
                            data={data}
                            index={index}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            updatePackageDetail={updatePackageDetail}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            removePackges={removePackges}
                            setIsDisable={setIsDisable}
                          />
                        );
                      })}
                      {errors.packages && touched.packages ? (
                        <InputError errorTitle={errors.packages} />
                      ) : null}
                    </div>
                    {/* add button */}
                    <div className="text-center mt-4">
                      <button
                        value={"Add"}
                        type="button"
                        onClick={() => {
                          setPackagesArray([
                            ...packagesArray,
                            {
                              package_id: "",
                              description: "",
                              price: "",
                              no_of_items: 1,
                            },
                          ]);
                        }}
                        className="text-mainthemeBlack  px-6 py-2 "
                      >
                        + ADD
                      </button>
                    </div>
                  </div>
                  {/* Submit button */}
                  <div className="flex justify-end mt-3 gap-2">
                    <DefaultPrimaryButton
                      value="Cancel"
                      onClick={() => navigate("/sales")}
                    />
                    <DefaultButton
                      type="submit"
                      value={isEditMode ? "Next" : "Create"}
                      disabled={isDisable}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModel
          isLoading={isLoading}
          confirmText={isEditMode === "edit" ? "Edit" : "Create"}
          onClose={(e) => {
            setShowConfirmationModal(false);
          }}
          onConfirm={() => handleConfirm()}
        />
      )}
    </>
  );
};

export default CreateEditSales;
