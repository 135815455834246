import React, { useEffect, useState } from "react";
import { SettingVector, SortDownArrow } from "../../assets/svg/AllSvg";
import { AddButton, Badge } from "../../components/micro";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import Action from "../../common/Action";
import CustomTable from "../../components/customtable/CustomTable";
import Toggles from "../../common/Toggles";
import ConfirmationModel from "../../common/ConfirmationModel";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePackage,
  getPackageList,
  updatePackageStatus,
} from "../../redux/package/slice";
import RippleLoader from "../../common/RippleLoader";

const Package = () => {
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [packageIdToDelete, setPackageIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // selector
  const { packageList, packageListLoading } = useSelector((store) => ({
    packageList: store?.packageData?.getPackageListStatus?.data,
    packageListLoading: store?.packageData?.loading,
  }));

  //Get Package List
  useEffect(() => {
    dispatch(
      getPackageList({
        pageCount: pageCount,
        pageLimit: pageLimit,
        sort_by: sortBy,
        last_page: lastPage,
        sort_direction: sortDirection === "asc" ? "asc" : "desc",
      })
    ).then((response) => {
      if (response?.payload?.data?.last_page) {
        setLastPage(response.payload.data.last_page);
      }
    });
  }, [dispatch, pageCount, pageLimit, sortBy, sortDirection]);

  // update status
  const updateStatus = (element) => {
    dispatch(
      updatePackageStatus({
        id: element?.id,
        data: {
          status: element?.status === "active" ? "inactive" : "active",
        },
      })
    ).then((res) => {
      if (res.type === "updatePackageStatus/fulfilled") {
        dispatch(
          getPackageList({
            pageCount: pageCount,
            pageLimit: pageLimit,
            sort_by: sortBy,
            sort_direction: sortDirection === "asc" ? "asc" : "desc",
          })
        );
      }
    });
  };
  //delete psckage
  const deletePackageId = () => {
    setIsLoading(true);
    if (!packageIdToDelete) return;
    dispatch(deletePackage(packageIdToDelete)).then((res) => {
      if (res?.type === "deletePackage/fulfilled") {
        dispatch(
          getPackageList({
            pageCount: pageCount,
            pageLimit: pageLimit,
            sort_by: sortBy,
            sort_direction: sortDirection === "asc" ? "asc" : "desc",
          })
        );
      }
      setIsLoading(false);
      setConfirmDelete(false);
    });
  };

  //   table header
  const ColumnHeaders = () => (
    <>
      <th className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start">
        No.
      </th>
      <th className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start">
        <div
          className="flex items-center cursor-pointer gap-1"
          onClick={() => {
            setSortBy("name");
            setSortDirection(sortDirection === "desc" ? "asc" : "desc");
          }}
        >
          <span>Package Name</span>
          <SortDownArrow />
        </div>
      </th>
      <th className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start">
        <div
          className="flex items-center cursor-pointer gap-1"
          onClick={() => {
            setSortBy("price");
            setSortDirection(sortDirection === "desc" ? "asc" : "desc");
          }}
        >
          <span>Price</span>
          <SortDownArrow />
        </div>
      </th>
      <th className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start">
        <div
          className="flex items-center cursor-pointer gap-1"
          onClick={() => {
            setSortBy("require_quantity");
            setSortDirection(sortDirection === "desc" ? "asc" : "desc");
          }}
        >
          <span>Quantity Required</span>

          <SortDownArrow />
        </div>
      </th>
      <th className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start">
        Status
      </th>
      <th className="p-2 font-Proxima sm:text-sm text-xs font-medium py-4 px-4 text-start">
        Actions
      </th>
    </>
  );
  // Table Rows
  const DataRows = () => (
    <>
      {(packageList?.data?.data || []).map((item, index) => (
        <tr
          key={item?.id ?? "-"}
          className="font-Proxima shadow-sm hover:bg-shadedgrey border-b "
        >
          <td className="px-5 py-3 truncate sm:text-sm text-xs ">
            {" "}
            {(packageList?.data.current_page - 1) * packageList?.data.per_page +
              index +
              1}
          </td>
          <td className="px-5 py-3 truncate sm:text-sm text-xs">
            {item?.name ?? "-"}
          </td>
          <td className="px-5 py-3 truncate sm:text-sm text-xs">
            {item.price === 0
              ? "To Be Advise"
              : `$${Number(item?.price).toFixed(2)}` ?? "-"}
          </td>
          <td className="px-5 py-3 truncate sm:text-sm text-xs">
            <div className="flex justify-start">
              <Badge
                className={`uppercase ${
                  item?.require_quantity === 1
                    ? "border-shaded_green text-green"
                    : "border-shaded_red text-lightBrown"
                }`}
                value={item?.require_quantity === 1 ? "Yes" : "No"}
              />
            </div>
          </td>
          <td className="px-5 py-3 truncate sm:text-sm text-xs">
            <Toggles
              checked={item?.status === "active"}
              toggleFor={item?.id}
              onChange={() => updateStatus(item)}
            />
          </td>
          <td className="flex px-5 py-3">
            <Action
              optionList={
                <>
                  <li
                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                    onClick={() =>
                      navigate("/view-package", {
                        state: { package: item, mode: "edit" },
                      })
                    }
                  >
                    View/Edit
                  </li>
                  <li
                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                    onClick={() => {
                      setPackageIdToDelete(item?.id);
                      setConfirmDelete(true);
                    }}
                  >
                    Delete
                  </li>
                </>
              }
            />
          </td>
        </tr>
      ))}
    </>
  );
  return (
    <>
      <div className="space-y-5">
        <div className="mb-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <SettingVector />
              <h6 className="font-opensans font-normal sm:text-xl text-lg ">
                Packages Setting
              </h6>
            </div>
            <AddButton
              value="Add Package"
              onClick={() =>
                navigate("/create-edit-package", {
                  state: { mode: "create" },
                })
              }
            />
          </div>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={"Packages Setting"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        {/* customtable */}

        {packageListLoading ? (
          <RippleLoader />
        ) : (
          <div className="w-full bg-white shadow rounded-lg">
            <CustomTable
              columnHeaders={<ColumnHeaders />}
              dataRows={<DataRows />}
              data={packageList?.data}
              showPagination={true}
              overflowVisibleLg={true}
              setPaginationCurrentLimit={setPageLimit}
              isLoading={isLoading}
              paginationCurrentLimit={pageLimit}
              total={packageList?.data?.total}
              current={pageCount}
              paginationData={(crPage) => setPageCount(crPage)}
              lastPage={lastPage}
            />
          </div>
        )}
      </div>
      {confirmDelete && (
        <ConfirmationModel
          isLoading={isLoading}
          confirmText={"Delete"}
          onClose={(e) => {
            setConfirmDelete(false);
          }}
          onConfirm={deletePackageId}
        />
      )}
    </>
  );
};

export default Package;
