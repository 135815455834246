import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Get Package List
export const getDashboardList = createAsyncThunk(
  "getDashboardList",
  async ({ from, to }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`dashboard?from=${from}&to=${to}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder

      // Get Dashboard
      .addCase(getDashboardList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardList.fulfilled, (state, action) => {
        state.loading = false;
        state.getDashboardListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getDashboardList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
const { reducer } = dashboardSlice;
export default reducer;
