import React from "react";
import { DeleteIcon, FileUpload } from "../assets/svg/AllSvg";

const FileUploadChoose = ({
  handleFileChange,
  className,
  uploadedFiles,
  onDelete,
  disabled,
}) => {
  const handleDeleteClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(index);
  };
  return (
    <div
      className={`relative flex flex-col items-center justify-center border-2 border-dashed border-borderDot cursor-pointer rounded-2xl overflow-hidden px-4 py-8 ${className}`}
    >
      {uploadedFiles?.length ? (
        <div className="mt-4 flex flex-wrap">
          {uploadedFiles.map((fileURL, index) => (
            <div key={index} className="relative mr-2 mb-2">
              <img
                src={fileURL}
                alt={`Uploaded preview ${index}`}
                className="w-24 h-24 object-cover rounded-lg"
              />
              <button
                type="button"
                onClick={(e) => handleDeleteClick(e, index)}
                className="absolute -top-4 -right-4 p-1 rounded-full hover:bg-borderTheme bg-white border border-gray h-7 w-7 flex justify-center items-center z-10"
              >
                <DeleteIcon />
              </button>
            </div>
          ))}
        </div>
      ) : null}
      <input
        className="absolute top-0 left-0 w-full h-full opacity-0 pointer-events-auto"
        type="file"
        onChange={handleFileChange}
        accept="jpeg, jpg, pdf, png"
        disabled={disabled}
        multiple
      />
      <FileUpload width={60} height={60} className="mb-4" />
      <p className="text-base mb-2 font-Proxima">
        Drag and Drop or{" "}
        <span className="text-[#0288d1] font-Proxima">choose your file</span>{" "}
        for upload
      </p>
      <p className="text-light_grey text-base font-Proxima">
        jpeg, jpg, pdf, png (max. 25mb)
      </p>
    </div>
  );
};

export default FileUploadChoose;
