import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FileUploadBack, SettingVector } from "../../assets/svg/AllSvg";
import Breadcrumb from "../../common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { getPackage } from "../../redux/package/slice";
import RippleLoader from "../../common/RippleLoader";

const ViewPackage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const packageData = location?.state?.package;

  //  Selector
  const { getpackageData, loading } = useSelector((store) => ({
    getpackageData: store?.packageData?.getPackageStatus?.data?.data,
    loading: store?.packageData?.loading,
  }));

  useEffect(() => {
    if (packageData?.id) {
      dispatch(getPackage(packageData?.id));
    }
  }, [dispatch, packageData]);

  return (
    <>
      <div className="space-y-5">
        <div className="mb-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <SettingVector />
              <h6 className="font-opensans font-normal sm:text-xl text-lg ">
                Customers
              </h6>
            </div>
          </div>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbParent2={"Packages Setting"}
            breadCrumbActive={"Edit Package"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <div className="bg-lightGrey p-6 rounded-lg">
          <div className="flex justify-between items-center border-b border-b-secondary_grey pb-5 mb-4">
            <div className="flex items-center gap-2">
              <SettingVector />
              <p className="text-xl font-normal text-primary_black">
                Package Details
              </p>
            </div>
            <div>
              <p
                className="text-mainthemeBlack border-b border-b-primaryBlack cursor-pointer"
                onClick={() =>
                  navigate("/create-edit-package", {
                    state: { package: packageData, mode: "edit" },
                  })
                }
              >
                Edit
              </p>
            </div>
          </div>
          <div className="space-y-4">
            <div>
              {/* <FileUploadBack /> */}
              {loading ? (
                <>
                  <RippleLoader />
                </>
              ) : (
                <>
                  <div className="flex gap-5">
                    {getpackageData?.images?.length > 0 ? (
                      getpackageData?.images?.map((item, index) => (
                        <div className="sm:w-[338px] sm:h-[240px]" key={index}>
                          <img
                            src={item?.document?.url}
                            alt={
                              item?.document?.description || "Image description"
                            }
                            className="w-[338px] h-[240px] object-cover"
                          />
                        </div>
                      ))
                    ) : (
                      <FileUploadBack />
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              <p className="text-sm font-normal text-primary_black">Pricing:</p>
              <p className="text-xl font-normal text-mainthemeBlack">
                {getpackageData?.price === 0
                  ? "To Be Advise"
                  : `$${getpackageData?.price}`}
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-normal text-primary_black">
                  Package Name:
                </p>
                <p className="text-base font-bold text-primary_black">
                  {getpackageData?.name}
                </p>
              </div>
              <div>
                <p className="text-sm font-normal text-primary_black">
                  Package Items:
                </p>
                <p className="text-base font-bold text-primary_black">
                  <ul className="list-disc pl-5 text-base font-bold text-primary_black">
                    {getpackageData?.items?.map((item, index) => (
                      <li key={index}>{item?.name}</li>
                    ))}
                  </ul>
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-normal text-primary_black">
                  Package Details:
                </p>
                <p className="text-base font-bold text-primary_black">
                  {getpackageData?.description}
                </p>
              </div>
              <div>
                <p className="text-sm font-normal text-primary_black">
                  Require Quantity:
                </p>
                <p className="text-base font-bold text-primary_black">
                  {getpackageData?.require_quantity === 1 ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPackage;
