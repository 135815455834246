import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack, DollarVactor } from "../../assets/svg/AllSvg";
import { DefaultButton } from "../../components/micro";
import { Logo } from "../../assets/images";
import Breadcrumb from "../../common/Breadcrumb";
import {
  getOneSales,
  sendEmail,
  updatesalesStatus,
} from "../../redux/sales/slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment/moment";
import ConfirmModal from "../../common/ConfirmModal";
import RippleLoader from "../../common/RippleLoader";

const SalesQuatation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const isEditMode = location?.state?.mode;
  const salesData = location?.state?.sales;

  //  Selector
  const { getSalesData, loading } = useSelector((store) => ({
    getSalesData: store?.salesData?.getOneSalesStatus?.data?.data,
    loading: store?.salesData?.loading,
  }));

  // get sales
  useEffect(() => {
    if (salesData?.id) {
      dispatch(getOneSales(salesData?.id));
    }
  }, [salesData, dispatch]);
  //update sale only save button
  const handleConfirm = () => {
    setShowConfirmationModal(false);

    if (isEditMode === "cancel") {
      dispatch(
        updatesalesStatus({
          id: salesData?.id,
          data: { status: "cancelled", payment_status: "unpaid" },
        })
      ).then((res) => {
        if (res?.type === "updatesalesStatus/fulfilled") {
          navigate("/sales");
        }
      });
    } else {
      navigate("/sales");
    }
  };
  // send email and update status
  const handleConfirmSecondary = () => {
    setShowConfirmationModal(false);

    const sendEmailCall = () => {
      dispatch(sendEmail({ id: salesData?.id })).then((emailResponse) => {
        if (emailResponse?.type === "sendEmail/fulfilled") {
          navigate("/sales");
        }
      });
    };

    if (isEditMode === "cancel") {
      dispatch(
        updatesalesStatus({ id: salesData?.id, data: { status: "cancelled" } })
      ).then((statusResponse) => {
        if (statusResponse?.type === "updatesalesStatus/fulfilled") {
          sendEmailCall();
        }
      });
    } else {
      sendEmailCall();
    }
  };

  const totalAmount = getSalesData?.packages
    ?.reduce((sum, pkg) => sum + pkg.price * (pkg.no_of_items || 1), 0)
    .toFixed(2);

  return (
    <>
      <div className="space-y-5">
        <div className="mb-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <DollarVactor />
              <h6 className="font-opensans font-normal sm:text-xl text-lg ">
                Sales
              </h6>
            </div>
          </div>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbParent2={"Sales"}
            breadCrumbActive={"Edit Sales"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
      </div>
      {loading ? (
        <RippleLoader />
      ) : (
        <div className="relative">
          <div className="shadow md:p-10 p-2 mt-2">
            <div className="grid lg:grid-cols-4">
              <div className="col-span-3">
                <img src={Logo} alt="" />
              </div>
              <div className="flex flex-col gap-2">
                <p className="sm:text-xl text-base font-normal font-poppins ">
                  {" "}
                  Yi Wei Athletic Association{" "}
                </p>
                <div className="sm:text-sm text-xs font-normal text-secondary_black">
                  <p>Blk 446 Hougang ave 8 #B1-1635 Singapore 530446</p>
                  <p>Tel:6489 5766 Fax:6487 6276 Hp:9272 0272</p>
                  <p>Website: www.yiwei.com.sg/ </p>
                  <p>Email: enquiry@yiwei.com.sg</p>
                </div>
              </div>
            </div>
            <div className="my-2">
              <p className="sm:text-2xl text-xl font-normal">Quotation</p>
            </div>
            <div className="flex justify-between">
              <div className="sm:text-sm text-xs font-normal txet-secondary_black">
                <p>Quotation ID: {getSalesData?.quotation_id}</p>
                <p>
                  Date:{" "}
                  {getSalesData?.created_at
                    ? moment(getSalesData.created_at).format("DD/MM/YYYY")
                    : ""}
                </p>
              </div>
              <div>
                <p className="sm:text-sm text-xs font-normal txet-secondary_black text-end">
                  Total Amount:
                </p>
                <span className="lg:text-4xl sm:2xl text-base text-mainthemeBlack font-normal">
                  ${totalAmount}
                </span>
              </div>
            </div>
            <div className="mt-4 grid md:grid-cols-2  justify-between">
              <div class="sm:text-sm text-xs max-w-[256px]">
                <p>
                  <span class=" inline-block w-20">Attn To:</span>
                  {getSalesData?.name}
                </p>
                <p>
                  <span class=" inline-block w-20">Tel:</span>
                  {getSalesData?.phone}
                </p>
                <p>
                  <span class=" inline-block w-20">Email:</span>
                  {getSalesData?.email}
                </p>
                <p>
                  <span class=" inline-block w-20">Address:</span>
                  {getSalesData?.address} {getSalesData?.postal_code}
                </p>
              </div>
              <div className="md:mt-0 mt-2">
                <div className="bg-lightPrimaryGrey p-4 rounded-md grid grid-cols-2 justify-between">
                  <div className="sm:text-sm text-xs">
                    <p className="text-sm text-light_Grey">
                      Performance Date & Time:
                    </p>
                    <p>
                      {" "}
                      {getSalesData?.performance_date_time
                        ? moment(getSalesData.performance_date_time).format(
                            "DD/MM/YYYY h:mm a"
                          )
                        : ""}
                    </p>
                  </div>
                  <div className="sm:text-sm text-xs">
                    <p className="text-sm text-light_Grey">
                      Performance Location:
                    </p>
                    <p>
                      {" "}
                      {getSalesData?.performance_address}{" "}
                      {getSalesData?.performance_postal_code}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {/* table */}
              <table className="table w-full mt-5 ">
                <thead>
                  <tr className="text-left bg-lightGrey ">
                    <th className="p-4  sm:text-sm text-xs font-medium">S/N</th>
                    <th className="p-4  sm:text-sm text-xs font-medium">
                      Item & Package
                    </th>
                    <th className="p-4 sm:text-sm text-xs font-medium">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getSalesData?.packages?.map((item, index) => (
                    <tr key={index} className="border-b overflow-auto">
                      <td className="p-4 ">
                        {String.fromCharCode(65 + index)}
                      </td>
                      <td className="p-4 flex flex-col gap-3">
                        <p className="text-sm lg:text-base  font-normal ">
                          {item.package?.name}
                        </p>

                        {item?.description && item.description.trim() !== "" ? (
                          <>
                            <p>
                              Golden Package {String.fromCharCode(65 + index)}{" "}
                              Includes:
                            </p>
                            <ul className=" ml-5">
                              <li className="sm:text-sm lg:text-base text-xs font-normal">
                                {item?.description}
                              </li>
                            </ul>
                          </>
                        ) : null}
                        {item.package?.items?.length > 0 ? (
                          <>
                            <ul className="list-disc ml-5">
                              {item.package.items.map((packageItem) => (
                                <li
                                  key={packageItem.id}
                                  className="sm:text-sm lg:text-base text-xs font-normal"
                                >
                                  {packageItem.name}
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : null}
                      </td>
                      <td className="p-2 sm:text-base text-sm">
                        ${(item?.price * (item?.no_of_items || 1)).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* terms condition */}
            {isEditMode === "cancel" ? (
              <div className="mt-6 sm:flex items-start justify-between">
                <div className="text-xs">
                  <p>TERMS AND CONDITIONS:</p>
                  <p>
                     *Cheque issue must be crossed and made payable to "Yi Wei
                    Athletic Association".
                  </p>
                  <p className="mt-5">
                     *Note: This is a Computer generated invoice, no signature
                    is required.
                  </p>
                </div>
                <div className="text-sm font-normal text-black ">
                  <p>Subtotal: ${totalAmount}</p>
                  {/* <p>Deposit: -</p> */}
                  <p>Total: ${totalAmount}</p>
                </div>
              </div>
            ) : (
              <div className="mt-6 sm:flex items-start justify-between">
                <div className="text-xs">
                  <p>TERMS AND CONDITIONS:</p>
                  <p>
                    *30 days credit payment terms upon completion of
                    performance.
                  </p>
                  <p>
                    *50% of agreed price will be charged upon cancellation after
                    confimation.
                  </p>
                  <p>
                    *In the event of performance cancellation due to wet
                    weather,no refunds or discounts will be provided.
                  </p>
                  <p>
                    *In the event of performance stunt mistakes,no refunds or
                    discounts will be provided.
                  </p>
                  <p>
                    *Quotation is only valid for X07 days subjected to our
                    schedule availability.
                  </p>
                  <p>Thank you.</p>
                  <p className="mt-5">
                    *Note: This is a Computer generated invoice, no signature is
                    required.
                  </p>
                </div>
                <div className="text-sm font-normal text-#000000 ">
                  <p>Subtotal: ${totalAmount}</p>
                  {/* <p>Deposit: -</p> */}
                  <p>Total: ${totalAmount}</p>
                </div>
              </div>
            )}

            {/* Signature */}
            {isEditMode === "cancel" ? null : (
              <div className="sm:flex justify-between mt-5">
                <div className="sm:w-1/4 w-44">
                  <div>
                    <p className="text-primaryblack">Yours Truly,</p>
                  </div>
                  <div className="p-4 ">{/* <img src={Sign} alt="" /> */}</div>
                  <div className="border-t text-sm text-primaryblack">
                    <p>Marcus Tock</p>
                    <p>Yiwei Athletic Association </p>
                    <p>Hp: 9999 222</p>
                  </div>
                </div>
                <div className="sm:w-1/4 w-44 sm:mt-0 mt-3">
                  <div>
                    <p className="text-primaryblack">Confirmed by,</p>
                  </div>
                  <div className="p-4"></div>
                  <div className="border-t text-sm text-primaryblack">
                    <p>Signature & Date</p>
                    <p>Name: </p>
                    <p>Designation</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {isEditMode === "cancel" ? (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl text-red-500 opacity-30 rotate-[-45deg] bg-lightGrey w-1/2 text-center p-4 text-light_Grey">
              Cancelled
            </div>
          ) : null}
          <div className="flex justify-end items-center gap-3">
            <div className="cursor-pointer ">
              <button
                className="flex gap-2 items-center"
                onClick={() => navigate("/sales")}
              >
                <ArrowBack />
                <p>Back</p>
              </button>
            </div>
            <DefaultButton
              value={isEditMode === "view" ? "Save" : "Edit"}
              onClick={() => {
                if (isEditMode === "edit") {
                  navigate("/create-edit-sales", {
                    state: { sales: salesData, mode: "edit" },
                  });
                } else {
                  setShowConfirmationModal(true);
                }
              }}
            />
          </div>
        </div>
      )}

      {showConfirmationModal && (
        <ConfirmModal
          confirmText={
            isEditMode === "edit"
              ? "Edit"
              : isEditMode === "cancel"
              ? "Cancel"
              : "Create"
          }
          btnText={isEditMode === "view" ? "Create" : "Save"}
          btnText1={isEditMode === "view" ? "Create & Email" : "Save & Email"}
          onClose={() => setShowConfirmationModal(false)}
          onConfirm={handleConfirm}
          onConfirmSecondary={handleConfirmSecondary}
        />
      )}
    </>
  );
};

export default SalesQuatation;
