// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  getCustomerListStatus: { data: null },
  getcompanyListStatus: { loading: false, data: null },
  getCustomerStatus: { data: null },
  createCustomersStatus: { data: null },
  deleteCustomerStatus: { data: null },
};
