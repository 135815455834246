import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/slice";
import customersSlice from "./customer/slice";
import addressSlice from "./address/slice";
import PackagesSlice from "./package/slice";
import uploadFileSlice from "./uploadFile/slice";
import salesSlice from "./sales/slice";
import dashboardSlice from "./dashboard/slice";

const rootReducer = {
  authData: authSlice,
  customerData: customersSlice,
  addressData: addressSlice,
  packageData: PackagesSlice,
  fileData: uploadFileSlice,
  salesData: salesSlice,
  dashboardData: dashboardSlice,
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
